import React from 'react';

import DemoThumbnail from '~/pages/Index/components/DemoThumbnail/DemoThumbnail';

const IndexApp = () => {
    return <div className = "d-flex flex-column justify-content-center align-items-center text-center text-light">
            <div className = "content-section p-3 mt-3">
                <p className = 'fw-bold'>
                    Discover the 3IA Côte d&apos;Azur technologies developed by the research teams through interactive demos and videos
                </p>
                <div className = 'd-flex justify-content-evenly align-items-middle flex-wrap'>
                    <span className = 'icon-non-technical'>&nbsp;Non technical users</span>
                    <span className = 'icon-technical'>&nbsp;Technical users</span>
                    <span className = 'icon-video'>&nbsp;Video</span>
                    <span className = 'icon-interactive'>&nbsp;Interactive demo</span>
                    <span className = 'icon-restricted'>&nbsp;Restricted access</span>
                </div>
            </div>
            <div className = 'my-4 d-flex justify-content-center align-items-center flex-wrap'>
                {
                    DEMOS.map((demo, i) => {
                        return <DemoThumbnail
                            key = { i }
                            title = { demo.title }
                            content = { demo.content }
                            url = { demo.url }
                            logo_url = { demo.logo_url }
                            target_users = { demo.target_users }
                            type = { demo.type }
                            restricted = { demo.restricted }
                        />;
                    })
                }
                {
                    USER_IS_ADMIN ?
                        <div className = 'add-demo-thumbnail card bg-primary m-3 p-3'>
                            <a className = 'link-light fw-bold text-center' href = '/admin/demos/demo/add/'>
                                <h1>+</h1>
                                <div>Add new demo</div>
                            </a>
                        </div> :
                        null
                }
            </div>
    </div>;
};

export default IndexApp;
