import React from 'react';
import { createRoot } from 'react-dom/client';

import "~/shared/jquery-ui/jquery-ui";

import IndexApp from './containers/IndexApp';

import "~/shared/jquery-ui/jquery-ui.css";

import '~/scss/pages/index.scss';

import '~/assets/images/3ia_logo_round.png';

createRoot(document.getElementById('index-app')).render(<IndexApp />);

window.onload = () => {
    $("#landing-animation-logo").animate({ height: "20vw", width: "20vw" }, { duration: 100 });
    $("#landing-animation-logo").animate(
        { height: "15vw", width: "15vw" },
        {
            duration: 250,
            easing: "easeOutCubic",
            complete: () => {
                $("#logo-fading-circle").css("opacity", 0.75);
                $("#logo-fading-circle").animate(
                    { width: "25vw", height: "25vw", opacity: 0 },
                    { duration: 400, easing: "easeOutCirc" }
                );
                setTimeout(() => {
                    $("#landing-animation-title").animate(
                        { maxWidth: "100vw"},
                        { duration: 750, easing: "easeInCubic" });
                }, 200);
            }
        }
        
    );
    setTimeout(() => {
        $("#landing-animation").animate(
            { opacity: 0 },
            {
                duration: 400,
                queue: false,
                complete: () => { $("#landing-animation").remove(); }
            }
        );
        $("#landing-upper-background").animate(
            { height: $("#header").outerHeight(), backgroundColor: "rgba(37, 58, 78, 1)" },
            {
                duration: 1000,
                queue: false,
                easing: "easeInExpo",
                complete: () => { $("#landing-upper-background").fadeOut();  }
            }
        );
        $("#landing-lower-background").animate(
            { height: $("#footer").outerHeight() },
            {
                duration: 1000,
                queue: false,
                easing: "easeInExpo",
                complete: () => { $("#landing-lower-background").fadeOut();  }
            }
        );
    }, 2000);
};