import React, { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Modal from 'react-bootstrap/Modal';

const DemoThumbnail = (props) => {
    const [show, setShow] = useState(false);

    const handleShow = () => { setShow(true); };
    const handleClose = () => { setShow(false); };

    return <>
        <div className = 'card thumbnail m-3'>
            <div className = 'card-header bg-primary text-center text-white fw-bold'>{ props.title }</div>
            <div className = 'card-body bg-light d-flex justify-content-center align-items-middle p-0'>
                <img src = { props.logo_url } />
            </div>
            <div className = 'card-footer bg-primary text-white'>
                <div className = 'd-flex justify-content-evenly align-items-middle'>
                    {
                        props.target_users == "TECHNICAL" ? 
                            <span className = 'icon-technical'></span> :
                            <span className = 'icon-non-technical'></span>
                    }
                    {
                        props.type == "VIDEO" ? 
                            <span className = 'icon-video'></span> :
                            <span className = 'icon-interactive'></span>
                    }
                    {
                        props.restricted ? 
                            <span className = 'icon-restricted'></span> :
                            null
                    }
                </div>
            </div>
            <a className = 'stretched-link' onClick = { handleShow }></a>
        </div>
        <Modal show = { show } onHide = { handleClose } size = "lg" centered>
            <Modal.Header className = 'bg-primary text-light' data-bs-theme = "dark">
                { props.title }
                <button
                    type = "button"
                    className = "btn-close btn-close-white"
                    aria-label = "Close"
                    onClick = { handleClose }
                >
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className = 'fs-6'>
                    { parse(props.content) }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    props.url ?
                        <a type = "button" className = "btn btn-primary" href = { props.url }>
                            Access Demo
                        </a> :
                        null
                }
                <button type = "button" className = "btn btn-secondary" onClick = { handleClose }>Close</button>
            </Modal.Footer>
        </Modal>
    </>;
};
  
DemoThumbnail.propTypes = {
    title: PropTypes.string,
    logo_url: PropTypes.string,
    content: PropTypes.string,
    url: PropTypes.string,
    target_users: PropTypes.string,
    type: PropTypes.string,
    restricted: PropTypes.bool
};

export default DemoThumbnail;